/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CircleProgressBar {
  margin-bottom: 32px;

  @include desktop {
    margin-bottom: 40px;
  }

  circle {
    stroke-width: 8px;
    transform: scale(0.9);
    transform-origin: center;
  }
  
  &-Bar {
    stroke: var(--button-background);
    transition: stroke-dashoffset .5s linear;
    stroke-width: 12px !important;
  }
  
  &-Background {
    stroke: $black;
  }
}

.CircleProgress {
  display: flex;
  flex-flow: column;
  align-items: center;

  &-Percentage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-Text {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    margin-top: 12px;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}
