/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviewItem {
    margin: 0;
    word-break: break-word;
    padding-top: 16px;
    border-top: 1px solid $black;

    @include desktop {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 38px 32px;
        margin-top: 16px;
        border-top: 0 none;
    };

    &:last-child {
        margin-block-end: 0;
    }

    &:nth-child(2n+2) {
        @include desktop {
            border-left: 1px solid $grey;
        }
    }

    &::before {
        content: none;
    }

    &-ReviewTitle {
        width: 100%;
        margin-block-end: 12px;
        font-weight: 700;
        grid-area: title;

        @include mobile {
            margin-block-end: 14px;
        }
    }

    &-Content {

    }

    &-RatingSummary {
        line-height: 1;

        @include mobile {
            margin-block-end: 14px;
            width: 100%;
        }
    }

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 16px;

        @include mobile {
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;

            margin-block: 0 2px;
            margin-inline: 12px 0;

            @include mobile {
                margin-block: 0 3px;
                margin-inline: 14px 0;
            }
        }
    }

    &-ReviewContent {
        flex: 1 0 0;
        margin-inline-end: 48px;

        @include mobile {
            flex: 0 0 auto;
            margin-inline-start: 0;
            width: 100%;
            order: 3;
        }

        @include tablet {
            flex: auto;
            order: 15;
            margin-block-start: 18px;
        }

        .TextPlaceholder {
            display: block;
        }
    }

    &-ReviewAuthor {
        margin-block: 4px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        flex-direction: column;

        @include desktop {
            white-space: nowrap;
        }
    }

    &-ReviewDetails {
        margin-block-end: 0;
        text-align: justify;

        @include mobile {
            margin-block-end: 14px;
        }

        .TextPlaceholder {
            margin-block-start: 6px;
            line-height: 1.1;

            @include mobile {
                margin-block-start: 7px;
            }

            &::after {
                display: block;
            }
        }
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        margin-block: auto;
    }
}
