/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.FreeGiftProductWidget {
    &-Main {
        margin-bottom: 10%;

        .slick-slider {
            .slick-dots {
                bottom: -10%;
            }
        }
    }

    &-Placeholder {
        display: flex;
        flex-flow: nowrap;
        figure {
            flex: auto;
            padding: 5px;
        }
        .SliderWidget-FigureImage  {
            padding-bottom: 160%;
        }

    }
    &-Tabs {
        display: flex;
        justify-content: end;
    }
    &-Tab {
        cursor: pointer;
        padding: 5px;
        margin: {
            left: 10px;
            right: 10px;
        };
        &::before,
        &::after {
            content: '';
        }
        &_isActive{ 
            background-color: #000;
            color: #fff;
        }
    }
}