/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 18px;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    background: $white;
    height: fit-content;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;

    &:hover {
        @include desktop {}
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    .ProductWishlistButton {
        position: absolute;
        top: 7px;
        right: 5px;

        .ProductLinks-Slider & {
            right: 11px;

            @include desktop {
                right: 27px;
            }
        }

        .CategoryPage_ProductListWrapper_100 & {
            display: none;
        }
    }

    &_layout_grid {
        margin-bottom: 22px;

        @include desktop {
            margin-bottom: 41px;
        }

        .CategoryPage_ProductListWrapper_100 & {
            @include desktop {
                margin-bottom: 24px;
            }
        }
    }

    &-Promo {
        grid-column: 1 / -1;
        margin-bottom: 22px;

        @include mobile {
            .mobile {
                width: 100%;
            }
        }

        @include desktop {
            margin-bottom: 31px;
        }

        .ProductListWidget & {
            display: none !important;
        }

        >p {
            width: 100%;
            margin-bottom: 0;
        }

        .slick-slide {
            width: 100% !important;
        }
    }

    &-AdditionalContent,
    &-VisibleOnHover {
        .CategoryPage_ProductListWrapper_100 & {
            display: none;
        }
    }

    &-Content {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;

        .CategoryPage_ProductListWrapper_100 & {
            display: none;
        }

        &:hover {
            text-decoration: none !important;
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    >div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-AlternativeName,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name,
    &-AlternativeName {
        line-height: var(--product-card-name-line-height);
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-block-end: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 32px;

        @include desktop {
            line-height: 1.3;
        }
    }

    &-Name {
        font-weight: 500;

        &.CHANEL {
            display: block;
            font-weight: 700;
            white-space: nowrap;
            height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 2px;
        }
    }

    &-AlternativeName {
        display: block;
        height: 16px;
        margin-bottom: 2px;
        white-space: nowrap;
        height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 2px;
    }

    &-ProductConfigurableAttributes {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.0008em;
        margin-bottom: 6px;
        min-height: 14px;

        &:hover {
            text-decoration: none !important;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;
        min-height: 19px;
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 2px;

        .TextPlaceholder {
            margin-bottom: 0;
        }
    }

    &-Picture {
        padding-block-end: 100%;
        vertical-align: top;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-Tags {
        position: absolute;
        left: 0;
        top: 10px;
        width: calc(100% - 30px);
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .CategoryPage_ProductListWrapper_100 & {
            display: none;
        }

        &Bottom {
            position: absolute;
            left: 0;
            bottom: 8px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .CategoryPage_ProductListWrapper_100 & {
                display: none;
            }
        }
    }

    &-Tag {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;

        .CategoryPage_ProductListWrapper_100 & {
            display: none;

            &.FREEGIFT {
                display: block;
                font-size: 10px;
            }
        }

        &Grey {
            background: $greyF0F0F0;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.02em;
            padding: 4px 8px;

            .CategoryPage_ProductListWrapper_100 & {
                display: none;
            }
        }

        &Black {
            background: $black;
            color: $white;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.02em;
            padding: 4px 8px;

            .CategoryPage_ProductListWrapper_100 & {
                display: none;
            }
        }

        &Vegan {
            .CategoryPage_ProductListWrapper_100 & {
                display: none;
            }

            img {
                vertical-align: top;
            }
        }
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
            text-decoration: none !important;
        }
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;
        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;
        margin-bottom: 5px;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 15px;
        }

        .ProductReviewRating-Counter {
            display: none;
        }

        .CHANEL & {
            opacity: 0;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 4px;
        min-height: 23px;

        @include mobile {
            min-height: 20px;
        }

        @include mobile-extra-small {
            min-height: 36px;
        }

        .ProductPrice {
            min-height: 23px;
            max-height: 23px;
            display: flex;
            align-items: center;

            @include mobile {
                min-height: 20px;
                max-height: 20px;
            }

            @include mobile-extra-small {
                min-height: 36px;
                max-height: 36px;
            }
        }
    }

    &-Price {
        width: 100%;

        .ProductPrice {

            &-DiscountPercentage,
            &-PriceValue {
                margin-top: 0;
            }
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;

        @include mobile {
            display: none;
        }

        @include wide-mid-ultra-wide-desktop {
            font-size: 11px;
        }
    }

    &-Figure {
        flex-grow: 1;
        padding-bottom: 36px;
        padding-top: 32px;

        @include desktop {
            padding-bottom: 28px;
        }

        .CategoryPage_ProductListWrapper_100 & {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 10px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 23px;

            @include mobile {
                min-height: 20px;
            }

            @include mobile-extra-small {
                min-height: 36px;
            }
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}

.FreeGiftCategoryPage {
    &-ShortDescriptionContainer {
        min-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
