/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$black};
    --option-size: 32px;
    --option-text-color: #{$black}; // Move to root
    --option-border-color: var(--color-dark-gray);

    @include mobile {
        --option-border-color: var(--color-dark-gray);
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    display: block;

    &:not(:last-of-type) {
        margin-bottom: 10px;

        .ProductConfigurableAttributes-SwatchList & {
            margin-bottom: 0;
        }
    }

    + .ExpandableContentShowMore-ExpandableChildren {
        margin-top: 10px;
    }

    &-Color,
    &-String,
    &-Image,
    &-Text {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);

        &.Field {
            margin-top: 0;
        }
    }

    &-String {
        padding: 0 10px;
        margin-block-end: 6px;

        img {
            width: 50px;
        }
    }

    &-Image {
        object-fit: cover;
        padding: 0;
        position: relative;

        &Wrapper {
            span {
                display: none;
            }
        }

        .ProductActions .ProductAttributeValue-ImageWrapper & {
            width: 48px;
            height: 48px;
        }

        .ProductActions & {
            &Wrapper {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.0008em;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                padding: 4px 12px;

                span {
                    display: block;
                }
            }
        }
    }

    &-Image-Overlay {
        --option-check-mark-background: var(--color-white);

        inset-block-start: 0;
        inset-inline-start: 0;
        margin: 0;
        position: absolute;
    }
    &-Color {
        box-shadow: inset 0 0 0 1px #979797;
        box-shadow: inset 0 0 0 1px var(--option-border-color);
    }

    .ProductBundleItem-Swatches &,
    &-Color,
    &-Image-Overlay {
        font-size: 0;
        width: var(--option-size);

        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
        }

        &::before {
            height: calc(var(--option-size) + 8px);
            width: calc(var(--option-size) + 8px);

            border-radius: inherit;
            box-shadow: inset 0 0 0 1px var(--primary-base-color);
            inset-inline-start: -4px;
            inset-block-start: -4px;
        }

        //&::after {
        //    height: calc(var(--option-size) / 5);
        //    inset-inline-start: calc(var(--option-size) / 3.3);
        //    inset-block-start: calc(var(--option-size) / 3.3);
        //    width: calc(var(--option-size) / 2.5);
        //
        //    transform: rotate(-45deg);
        //
        //    [dir="rtl"] & {
        //        transform: scale(-1, 1) rotate(45deg);
        //    }
        //
        //    border-inline-start: 2px solid var(--option-check-mark-background);
        //    border-block-end: 2px solid var(--option-check-mark-background);
        //}

        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-Image-Overlay {
        .ProductActions .ProductAttributeValue-ImageWrapper + & {
            width: 100%;

            &::before {
                height: calc(56px);
                width: calc(100%);
                box-shadow: inset 0 0 0 1px $black;
                opacity: 0.2;
                left: 0;
                top: 0;
            }

            &.selected {
                &::before {
                    opacity: 1;
                }
            }

            &:hover::before {
                opacity: 1;
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        border-radius: var(--button-border-radius);
        line-height: calc(var(--option-size) - 2px);
        min-width: var(--option-size);
        font-weight: 400;
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--primary-base-color);
            font-weight: 600;
        }

        span {
            font-size: 12px;
        }

        .Field-SubLabel {
            font-weight: normal;
        }
    }

    &-String {
        &_isSelected {
            --option-text-color: var(--primary-base-color);
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;
        min-height: 0;

        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
        }

        label {
            vertical-align: top;

            &:first-of-type {
                padding-block-end: 0;
                order: 1;
                text-align: start;

                overflow: hidden;
                text-overflow: ellipsis;

                //display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 16px;
                vertical-align: top;

                .input-control {
                    inset-inline-start: 0;
                    margin: 0;
                    width: 14px;
                    height: 14px;
                    min-width: 14px;
                    vertical-align: top;
                    margin-inline-end: 14px;

                    @include desktop {
                        align-self: self-start;
                        margin-block-start: 2px;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include desktop {
                    border-color: var(--primary-base-color);
                }
            }
        }

        @include desktop {
            padding-inline: 0;
        }

        .CategoryFilterOverlay & {
            &.level {
                &-3 {
                    position: static;
                    margin-right: 13px;
                    height: 0;
                }

                &-4 {
                    position: static;
                    margin-right: 26px;
                    height: 0;
                }
                &-5 {
                    position: static;
                    margin-right: 39px;
                    height: 0;
                }
            }
        }
    }

    &:hover {
        @include desktop {
            --option-border-color: var(--primary-base-color);
            --option-text-color: var(--primary-base-color);
        }

        text-decoration: none;
    }

    &_isNotAvailable {
        opacity: .5;
        cursor: default;
        pointer-events: none;

        .ProductConfigurableAttributes-SwatchList & {
            pointer-events: initial;
            cursor: pointer;
        }

        .ProductAttributeValue-Color {
            &::after {
                @include not-available;

                background-color: var(--option-check-mark-background);
            }
        }
    }

    .ProductBundleItem-Swatches & {
        border: 1px solid $greydddddd;
        height: 54px;
        width: 54px;

        @include desktop {
            height: 63px;
            width: 63px;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
        }

        &:before {
            left: -5px !important;
            top: -5px;
            width: 62px;
            height: 62px;

            @include desktop {
                left: -1px !important;
                top: -1px;
                width: 63px;
                height: 63px;
            }
        }
    }

    &.level-5 {
        display: none;

        &.isParentSelected {
            display: block;
        }
    }
}

