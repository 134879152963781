/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CategoryProductList {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
    }

    &-Page {
        &_layout {
            &_grid {
                display: grid;
                grid-auto-rows: max-content;
                grid-column-gap: 23px;

                @include narrow-desktop {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include wide-desktop {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include wide-mid-ultra-wide-desktop {
                    grid-column-gap: 20px
                }

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mobile {
                    grid-column-gap: 16px;
                    // remove margin from top to other pages
                    grid-template-columns: 1fr 1fr;
                    border: 0;
                }

                @include desktop {
                    grid-column: 2;
                    margin-block-start: 12px;
                }

                .CategoryPage_ProductListWrapper_50 & {
                    @include narrow-desktop {
                        grid-template-columns: repeat(3, 1fr);
                    }

                    @include wide-desktop {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include tablet {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @include mobile {
                        // remove margin from top to other pages
                        grid-template-columns: 1fr 1fr;
                    }

                    .ProductCard_layout_grid {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            @include narrow-desktop {
                                order: -2;
                            }
                        }
                    }

                    .ProductCard-Promo {
                        @include narrow-desktop {
                            order: -1;
                        }
                    }
                }

                .CategoryPage_ProductListWrapper_100 & {
                    @include wide-desktop {
                        grid-template-columns: repeat(10, 1fr);
                    }

                    @include tablet {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include mobile {
                        // remove margin from top to other pages
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include narrow-desktop {
                        grid-template-columns: repeat(6, 1fr);
                    }

                    .ProductCard_layout_grid {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10),
                        &:nth-child(11),
                        &:nth-child(12),
                        &:nth-child(13) {
                            @include narrow-desktop {
                                order: -2;
                            }
                        }

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10),
                        &:nth-child(11) {
                            @include wide-desktop {
                                order: -2;
                            }
                        }
                    }

                    .ProductCard-Promo {
                        @include narrow-desktop {
                            order: -1;
                        }

                        @include wide-desktop {
                            order: -1;
                        }
                    }
                }

                .CategoryPage_ProductListWrapper_0 & {
                    @include narrow-desktop {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @include wide-desktop {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @include tablet {
                        grid-template-columns: none;
                    }

                    @include mobile {
                        // remove margin from top to other pages
                        grid-template-columns: none;
                    }
                }

                // remove margin from top to other pages
                ~ .CategoryProductList-Page {
                    @include desktop {
                        margin-block-start: 0;
                    }
                }
            }

            &_list {
                display: block;
                max-width: 600px;

                .ProductCard-Link {
                    flex-direction: row;
                    max-width: 100%;
                    width: fit-content;

                    .Image {
                        width: 200px;
                    }

                    .ProductCompareButton {
                        .Image {
                            width: var(--compare-button-size);
                        }
                    }

                    .ProductWishlistButton {
                        .Image,
                        &-Heart {
                            height: var(--wishlist-heart-size);
                            width: var(--wishlist-heart-size);
                        }
                    }
                }
            }
        }

        // remove margin from top to other pages
        ~ .CategoryProductList-Page {
            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-ProductsMissing {
        padding: 12px;
        text-align: center;

        @include mobile {
            padding: 14px;
        }
    }

    &-More {
        min-height: 36px;

        @include mobile {
            min-height: 42px;
        }
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 17px;
        margin-block-end: 12px;
        padding: 1em 0;
        text-align: center;

        @include mobile {
            margin-block-end: 14px;
        }
    }
}
