/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductAttributes {
    font-size: 14px;
    line-height: 20px;

    &-Wrapper {
        @include desktop {
            padding-block-end: 32px;
        }
    }

    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            @include mobile {
                padding-block: 0;
            }
        }
    }

    &-AttributeBlock {
        break-inside: avoid;
        overflow: hidden;
        width: 100%;
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Group {
        margin: 0;
        padding-block: 32px 16px;
    }

    &-Attributes {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &-Attribute {
        @include desktop {
            padding-left: 20px;
        }

        &:before {
            @include desktop {
                content: "";
                background: $black;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                position: absolute;
                left: 8px;
                top: 9px;
                overflow: hidden;
            }
        }
    }

    &-ValueLabel,
    &-AttributeLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 12px;

        &:last-of-type {
            border: none;
        }
    }

    &-AttributeLabel {
        font-weight: 700;
        margin-bottom: 4px;

        .Cruelty-Free &,
        .Vegan & {
            display: none;
        }
    }

    &-ValueLabel {
        .Cruelty-Free &,
        .Vegan & {
            font-weight: 700;

            .ProductAttributeValue {
                display: flex;
                align-items: center;
                gap: 14px;

                &:after {
                    content: "";
                    background-image: url("/style/icons/Vegan-pdp.svg");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    width: 25px;
                    height: 24px;
                    flex-shrink: 0;
                }
            }
        }

        .Cruelty-Free & {
            .ProductAttributeValue {
                &:after {
                    background-image: url("/style/icons/Cruelty-Free.svg");
                }
            }
        }
    }

    &-Image {
        padding-block-end: 40%;
        mix-blend-mode: multiply;
        margin-inline-end: 120px;
        min-width: 360px;
        background: none;

        @include mobile {
            margin-inline-end: 140px;
            min-width: 420px;
            display: none;
        }
    }
}
