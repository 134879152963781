/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductWishlistButton {
    display: inline-block;
    height: var(--wishlist-heart-size);
    width: var(--wishlist-heart-size);

    .CartPage .CartItem & {
        width: auto;
        height: auto;

        @include mobile {
            padding: 4px 0 4px 14px;
        }
    }

    .WishlistItem-Edit & {
        width: 100%;
        height: auto;
    }

    .WishlistItem-Edit .ProductActions-ActionButtons & {
        width: 23px;
        height: 23px;
    }

    &-Button {
        &_isDisabled {    
            cursor: none;
            opacity: 0.5;
        }
        
    &.Button {
        background-color: unset;
        border: none;
        height: var(--wishlist-heart-size);
        padding: 0;

        &:not([disabled]):hover,
        &:hover,
        &:focus {
            background-color: unset;
            color: $black;
            height: var(--wishlist-heart-size);
            padding: 0;
        }

        .CartPage .CartItem & {
            background: none;
            color: $black;
            height: auto;
            display: flex;
            gap: 13px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            min-height: 24px;

            img {
                width: auto;
                height: 18px;
            }
        }

        .Popup &,
        .CartItem-Edit & {
            span {
                display: none;
            }
        }

        .WishlistItem-Edit & {
            border: 1px solid $black;
            background: $black;
            width: 100%;
            height: 48px;

            &:not([disabled]):hover,
            &:hover,
            &:focus {
                border-color: $black;
                height: 48px;
            }

            img {
                display: none;
            }

            span {
                display: block;
            }
        }

        .WishlistItem-Edit .ProductActions-ActionButtons & {
            border: 0 none;
            background: none;
            width: auto;
            height: 23px;

            &:not([disabled]):hover,
            &:hover,
            &:focus {
                height: 23px;
            }

            span {
                display: none;
            }

            img {
                display: block;
            }
        }
    }
    }
}
