/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image
.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 761px;

    .amgiftcard & {
        height: 555px;

        @include wide-desktop {
            height: 705px;
        }
    }

    @include narrow-desktop {
        min-width: 507px;
    }

    @include mobile {
        height: calc(100vh/2);
        margin-block-end: 16px;
        margin-inline: 0;
    }

    &-Additional {
        flex: 0 0 auto;

        @include mobile {
            display: none;
        }

        .amgiftcard & {
            display: none !important;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }
    }

    &-SliderWrapper {
        flex: 1;

        .amgiftcard & {
            @include desktop {
                padding: 0 20px;
            }
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);
        padding-bottom: 70px;

        @include mobile {
            padding-bottom: 50px;
        }

        .amgiftcard & {
            padding-bottom: 0;
        }

        &_isImageZoomPopupActive {
            cursor: pointer;
            padding-bottom: 0;

            .Slider-Crumbs {
                display: none;
            }
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }

    &-SliderImage {
        .Image-Image {
            max-width: 732px;
            margin: 0 auto;
            left: 0;
            right: 0;

            .ImageZoomPopup & {
                width: auto !important;
                max-width: 100%;
            }
        }
    }

    .VideoThumbnail-Thumbnail {
        .Image-Image {
            max-width: 732px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 88% !important;

            .ImageZoomPopup & {
                max-width: 100%;
                width: auto !important;
            }
        }
    }

    &-ExpandIcon {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        bottom: 40px;

        .ImageZoomPopup & {
            display: none !important;
        }
    }
}
