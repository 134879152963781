/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    width: 100%;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block: 0;

        @include mobile {
            padding-inline: 0;
        }
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
            justify-content: center;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-Title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        position: relative;
        margin: 0 -14px 28px;
        padding: 0 16px;
        text-align: center;

        @include desktop {
            margin: 0 0 28px;
        }

        span {
            background: $white;
            padding: 0 16px;
            display: inline-block;
            vertical-align: top;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-bottom: 1px solid $black;
        }
    }

    &-Slider {
        .ProductCard {
            padding: 0 6px;

            @include desktop {
                padding: 0 22px;
            }

            @include tablet {
                padding: 0 12px;
            }

            @media(min-width: 1480px) and (max-width: 1640px) {
                padding: 0 12px;
            }
        }

        &.slick-slider {
            margin-left: -6px;
            margin-right: -6px;
            margin-bottom: 32px;

            @include desktop {
                margin-right: -22px;
                margin-left: -22px;
                margin-bottom: 40px;
            }

            .slick-prev {
                @include desktop {
                    left: -16px;
                }
            }

            .slick-next {
                @include desktop {
                    right: -16px;
                }
            }

            .slick-dots {
                position: static;
                margin-top: 15px;

                @include desktop {
                    margin-top: 20px;
                }
            }
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}
