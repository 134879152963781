/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviews {
    @include desktop {
        z-index: 1;
    }

    &-Wrapper {
        padding: 0;
        margin-bottom: 69px;

        @include desktop {
            padding: 36px 12px 0;
        }
    }

    &-Summary {
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            flex-direction: column;
        }
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-ExpandableContentHeading,
    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }
    }

    & &-Button {
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        margin: auto;
        width: auto;
        margin: 18px auto 0;
        display: block;
        background: $black;
        color: $white;

        @include desktop {
            margin-top: 32px;
        }
    }

    &-SummaryDetails {
        display: inline-block;
        margin-inline-start: 12px;
        margin-block: auto;
        font-size: 24px;
        font-weight: 700;

        @include mobile {
            margin-inline-start: 14px;
        }

        span {
            &::before {
                content: ' / ';
            }
        }
    }
}
