/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviewList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include desktop {
        margin: 0 -32px;
    }

    @include mobile {
        grid-template-columns: 1fr;
        grid-gap: 14px;
    }

    &-Title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-block-start: 36px;

        @include mobile {
            font-size: 28px;
            margin-block-start: 42px;
        }
    }
}
