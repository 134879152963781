/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.ProductAdvanceReview {
    width: 100%;

    @include desktop {
        display: flex;
        max-width: 100%;
        width: 610px;
        align-items: center;
        justify-content: space-around;
        gap: 56px;
        margin: 0 auto;
    }

    &-Summary {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-RatingsList {
            display: flex;
            flex-direction: column-reverse;

            @include desktop {
                width: 100%;
            }
        }

        &-RatingList {
            display: flex;
            align-items: center;
            margin: 2px 0;

            &:last-child {
                margin-bottom: 2px;
            }

            &:before {
                display: none;
            }

            &-Item {
                max-height: 4px;
                margin: 0 5px;
                top: 1px;
                display: block;
                background: #E5E5E5;
                width: 100%;
                border-radius: 2px;
                overflow: hidden;

                &-Line {
                    width: 100%;
                    height: 4px;
                    background: #000;
                    border-radius: 2px;
                    overflow: hidden;
                }
            }

            .count {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                width: 22px;

                &:last-of-type {
                    width: 32px;
                    text-align: right;
                }
            }
        }

        &Details {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            width: 100%;
            max-width: 232px;
            margin: 22px auto 0;

            @include desktop {
                display: flex;
                max-width: 100%;
                align-items: center;
                justify-content: center;
                gap: 32px;
                margin-top: 30px;
                border-bottom: 1px solid $black;
            }
        }

        .StarIcon {
            width: 34px;
            height: 33px;
        }
    }

    &-aggregatePercent {
        font-weight: 600;
        font-size: 40px;
        line-height: 51px;
        letter-spacing: 0.02em;
        margin-bottom: 8px;
    }

    &-aggregateCount {
        font-weight: 600;
        font-size: 16px;
        line-height: 50px;
        letter-spacing: -0.2px;
        margin-top: 8px;
    }
}