/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductInformation {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;

    &-Wrapper {
        padding: 0 !important;
    }

    &-ExpandableContentContent {
        line-height: 18px;

         + div {
             margin-block-end: 12px;
         }
    }

    &-ExpandableContentHeading{
        @include desktop {
            display: none;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-Description {
        @include default-list;

        p, li {
            font-size: 14px;
            line-height: 22px;
        }

        li {
            &::before {
                position: absolute;
                inset-inline-start: 1em;
                inset-block-start: .07em;
            }
        }

        p,
        li:last-child {
            margin-bottom: 0;
        }
    }

    &-Heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;

        @include desktop {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
