/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductTabs {
    display: none;
    border-block-end: 1px solid var(--secondary-base-color);

    @include desktop {
        display: flex;
        justify-content: space-between;
    }

    &-Wrapper {
        position: static;
        margin-bottom: 45px;

        @include mobile {
            background: $icegrey;
            padding: 0 16px !important;
            margin-left: -16px !important;
            margin-right: -16px !important;
        }

        .ExpandableContent-Heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
        }

        .ExpandableContent-Content_isContentExpanded {
            @include mobile {
                padding: 16px 0;
            }
        }

        .ExpandableContent {
            border-block-end: 0;
            position: static;

            &:last-of-type {
                @include mobile {
                    border-block-end: 0;
                }
            }
        }

        .ProductInformation-Content {
            @include desktop {
                border-block-start: 0;
            }
        }

        & > :first-child {
            border-block-start: 0;
        }
    }

    &-ContentWrapper {
        position: relative;
        padding: 39px 16px;

        &:before {
            content: "";
            background: $icegrey;
            position: absolute;
            width: 10000px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            .CHANEL & {
                display: none;
            }
        }

        .ExpandableContent-Button {
            @include desktop {
                display: none;
            }
        }
    }

    &-Content {
        z-index: 1;
        position: relative;

        .ExpandableContent-Content {
            margin-top: 0;
        }
    }

    &-ContentColumns {
        @include desktop {
            display: grid;
            grid-template-columns: 2fr 1fr;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &-ContentColumn {
        &_share {
            @include desktop {
                text-align: center;
            }

            .ProductActionsShare-container {
                position: static;
                background: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                width: 100%;

                &:before {
                    display: none;
                }
            }

            .ProductInformation {
                @include mobile {
                    display: none;
                }

                @include desktop {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
    }

    &Share {
        &-container {
            display: inline-block;

            @include mobile {
                margin-top: 10px !important;
            }
        }

        &-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 16px;
            text-align: left;

            @include desktop {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

section[aria-label="Product tabs"] {
    @include desktop {
        overflow: hidden;
    }
}

.ShippingReturns-Content {
    h3 {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin: 0 0 16px;
    }

    p {
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 20px;
    }
}
