/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductListWidget {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
        min-height: auto;
    }

    &-Page {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 12px;

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(6, 1fr);
        }

        @include mobile {
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
            border: 0;
        }

        .CmsPage-Wrapper_page_width_default & {
            @include mobile {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .CmsPage .HeroBrandPage-Wrapper &,
        .CategoryPage-Wrapper & {
            @include tablet {
                grid-template-columns: repeat(2, 1fr);
            }

            @include narrow-desktop {
                grid-template-columns: repeat(3, 1fr);
            }

            @include wide-desktop {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        position: relative;
        margin: 0 0 28px;
        padding: 0 14px;
        text-align: center;

        @include desktop {
            margin: 0 0 28px;
        }

        span {
            background: $white;
            padding: 0 16px;
            display: inline-block;
            vertical-align: top;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-bottom: 1px solid $black;
        }
    }
}
