/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.showGiftCardPreviewOverlay {
    &-container {
        position: fixed;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .close {
            position: absolute;
            top: 25px;
            right: 32px;
            color: white;
            z-index: 1;

            @include mobile {
                top: 23px;
                right: 13px;
            }
        }

        .container {
            padding: 0;
            display: grid;
            grid-template-columns: 5fr 4fr;
            grid-column-gap: 24px;

            @include mobile {
                display: block;
            }
        }


        .ProductActions,
        .product__info {
            position: static
        }

        .product {
            &__left {
                overflow: hidden;
                width: auto;
                margin: 0;

                > .ProductGallery-title {
                    @include desktop {
                        display: none;
                    }
                }
            }

            &__right {
                overflow: hidden;
                margin: 0;
                padding-top: 100px;

                @include mobile {
                    padding: 0 15px 0;
                }

                .product__prices {
                    @include mobile {
                        position: absolute;
                        top: 95px;
                        left: 15px;
                    }
                }
            }

            &__wrapper {
                margin: 0;
                width: 100%;
            }
        }

        .ProductActions {
            &-Title {
                font-size: 14px;
                line-height: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-Sku {
                font-size: 12px;
                color: black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-Section_type {
                &_name {
                    display: block;
                    position: absolute;
                    top: 16px;
                    left: 157px;
                    right: 50px;

                    @include mobile {
                        top: 60px;
                        left: 15px;
                    }
                }

                &_sku {
                    display: block;
                    margin: 0;
                    position: absolute;
                    top: 37px;
                    left: 157px;
                    right: 50px;

                    @include mobile {
                        top: 80px;
                        left: 15px;
                    }
                }
            }
        }

        .product-result {
            @include mobile {
                position: static;
                margin: 0 -15px;
            }
        }
    }

    &-bkg {
        position: fixed;
        background: rgba(0,0,0,0.5);
        z-index: 101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &-popup {
        max-width: 848px;
        max-height: 98%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 30px 0 0;
        z-index: 102;

        @include mobile {
            max-height: 100%;
            background: none;
            padding-right: 40px;
        }

        .ProductPage {
            @include mobile {
                background: white;
                padding-bottom: 0;
            }
        }

        .Table-Wrapper {
            overflow-x: auto;
        }

        .wrapper {
            width: 100%;
        }
    }
}
