@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 20px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin center-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@mixin float-label-container {
    display: block;
    position: relative;
}

@mixin float-label {
    label, > span {
        position: absolute;
        left: 10px;
        top: -13px;
        cursor: text;
        color: $black;
        transition: all .2s;
        @content;
    }
}

@mixin float-label-input {
    &::placeholder {
        opacity: 1;
        transition: all .2s;
    }
    &:placeholder-shown:not(:focus)::placeholder {
        opacity: 0;
    }
}

@mixin float-label-scaled {
    &:placeholder-shown:not(:focus) + * {
        font-size: 12px;
        @content;
    }
}
