/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductPage {
    overflow-x: hidden;

    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-inline: 16px;
    }

    &.CHANEL {
        font-family: $font-arial;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &-Wrapper {
        display: grid;
        //grid-template-columns: 1.5fr 1fr;
        grid-template-columns: calc(61% - 24px) 39%;
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;

        @include wide-desktop {
            //grid-template-columns: 1fr 483px;
            grid-template-columns: calc(100% - 507px) 483px;
        }

        @include desktop {
            grid-column-gap: 26px;
            grid-template-areas: '. actions';
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0 0 20px !important;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }
}

.PdpProductTabs {
    @include mobile {
        border-top: 1px solid $grey;
        padding-top: 32px;
        margin-inline: -16px;
    }

    &-Button {
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin-right: 43px;
        margin-bottom: 32px;
        order: -1;

        @include mobile {
            background: $greyF0F0F0;
            color: $black;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: calc(50% - 8px);
            overflow: hidden;
            text-align: center;
            padding: 0 5px;
        }

        &.active {
            font-weight: 600;
            border-bottom: 2px solid $black;
            pointer-events: none;

            @include mobile {
                border-bottom: 0 none;
                background: $black;
                color: $greyF0F0F0;
            }
        }
    }

    .ContentWrapper {
        display: flex;
        flex-wrap: wrap;

        &.ProductLinks-Wrapper {
            display: block;
        }
    }

    section,
    .RenderWhenVisible {
        width: 100%;
    }
}
