/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.ProductPopup {
  &.ProductPage {
    width: 982px;
    max-width: 100%;
    min-height: 0;
    margin: 0;
  }

  .ProductGallery-SliderImage {
    height: 100%;
  }

  .ProductPage-Wrapper {
    display: grid;
    //grid-template-columns: 1.5fr 1fr;
    grid-template-columns: calc(61% - 24px) 39%;
    grid-column-gap: 24px;
    grid-template-rows: min-content min-content;
    padding: 0;

    @include wide-desktop {
      //grid-template-columns: 1fr 483px;
      grid-template-columns: calc(100% - 441px) 415px;
    }

    @include desktop {
      grid-column-gap: 26px;
      grid-template-areas: '. actions';
      padding: 0 8px;


      .ProductActions {
        grid-area: actions;
      }
    }

    @include mobile {
      grid-template-columns: 100%;
    }
  }

  .ProductAction-UpdateCart {
    &.Button {
      width: 100%;
    }
  }

  .ProductGallery {
    &-Slider {
      &_isZoomInCursor {
        cursor: auto;
  
        .Slider-Wrapper {
          pointer-events: none;
        }
      }
    }
    @include desktop {
      height: 533px;
    }

    &-ExpandIcon {
      display: none;
    }

    &-Slider {
      padding-bottom: 58px;
    }

    .CarouselScroll-Content {
      padding-right: 0;
    }
  }

  .CarouselScrollItem {
    width: 72px;
    height: 80px;
  }

  .CarouselScroll {
    margin-top: 0;

    &-ContentWrapper:not(.active){
      .ProductPopup & {
        width: auto;
      }
    }
  }

  .Slider-Crumbs {
    @include desktop {
      bottom: 40px;
    }
  }

  .ProductActions-Help,
  .ProductActions_aroma_notes {
    display: none;
  }
}