/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductAlerts {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &-InStock,
    &-PriceDrop {
        text-align: left;
        // color: var(--primary-base-color);
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: var(--primary-dark-color);
        }

        [dir="rtl"] & {
            text-align: right;
        }
    }

    &-InStock {
        &,
        &.Button {
            font-size: 12px;
            margin-bottom: 10px;

            + .ProductActions-FindStore {
                margin-top: 0;
            }
        }
    }

    &Popup {
        .Button {
            width: 100%;
            font-size: 12px;
        }
    }
}
