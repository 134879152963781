/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductBundleItem {
    &-Wrapper {
        margin-block-start: 24px;

        @include desktop {
            margin-block-start: 0;
            width: 50%;
            display: flex;
            flex-direction: column;
        }
    }

    &-Title {
        font-size: 14px;
        font-weight: bold;
        margin-block-end: 12px;

        @include desktop {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.0008em;
        }
    }

    &-ProductTitle {
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        margin-block-start: 0;
        margin-block-end: 12px;

        @include desktop {
            font-size: 14px;
            line-height: 22px;
            font-weight: 700;
            letter-spacing: -0.0008em;
        }
    }

    &-DropdownWrapper {
        display: flex;
        align-items: center;
    }

    &-Qty.Field {
        margin-block: 0;
        margin-top: 12px;
        border: 1px solid $color-grey-E5;

        .ProductBundleItem-Qty {
            opacity: 1;
        }

        [type='number'] {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            ~ button {
                border: 0 none;
                width: 46px;
                height: 46px;
            }
        }
    }

    &-Select {
        flex-grow: 1;
        margin: 0;
    }

    &-Swatches {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-height: 170px;
        padding-left: 165px;

        @include desktop {
            border: 1px solid $black;
            padding: 6px 18px;
            padding-left: calc(45% + 22px);
            min-height: 286px;
            height: 100%;
        }

        @media (max-width: 350px) {
            padding-left: 100px;
        }

        .ProductAttributeValue {
            align-self: center;
            cursor: pointer;
            margin: 4px;

            @include desktop {
                margin: 1px;
            }

            &:disabled {
                opacity: 0.5;

                &:before {
                    display: none;
                }
            }
        }
    }

    &-SelectedSubLabel {
        .ProductCard-PriceWrapper {
            min-height: 0;
            margin-top: 12px;
            margin-bottom: 0;
        }

        .ProductPrice {
            min-height: 0;
            line-height: 15px;
        }
    }

    &-SelectedImage {
        width: 150px;
        margin-right: 16px;
        position: absolute;
        top: 0;
        left: 0;

        @include desktop {
            width: 45%;
            margin-right: 22px;
        }

        @media (max-width: 350px) {
            width: 95px;
            margin-right: 5px;
        }

        .Image {
            padding-bottom: 120%;

            @include desktop {
                padding-bottom: 284px;
                vertical-align: top;
            }
        }
    }

    &-AvailableOptions {
        display: flex;
        flex-wrap: wrap;
    }
}
