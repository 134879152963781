/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.MultiTabProduct {
 &Widget {
    &-Main {
        margin-bottom: 20px;

        @include desktop {
            margin-bottom: 40px;
        }

        .ProductCard {
            padding: 0 6px;

            @include desktop {
                padding: 0 22px;
            }

            @include tablet {
                padding: 0 12px;
            }

            @media(min-width: 1480px) and (max-width: 1640px) {
                padding: 0 12px;
            }

            .ProductWishlistButton {
                @include desktop {
                    right: 22px;
                }
            }
        }

        .ProductPrice {
            &-PriceBadge {
                @include tablet {
                    margin-right: 2%;
                }
            }

            &-DiscountPercentage {
                @include tablet {
                    padding: 3px 4px;
                }
            }
        }

        .ProductPrice del {
            @include tablet {
                margin-right: 2%;
            }
        }

        .slick-slider {
            margin-left: -6px;
            margin-right: -6px;

            @include desktop {
                margin-right: -22px;
                margin-left: -22px;
            }

            .slick-prev {
                @include desktop {
                    left: -16px;
                }
            }

            .slick-next {
                @include desktop {
                    right: -16px;
                }
            }

            .slick-dots {
                position: static;
                margin-top: 15px;

                @include desktop {
                    margin-top: 38px;
                }
            }
        }

        .AddToCart.Button {
            &:not([disabled]) {
                &:hover {
                    background: var(--cms-block-fc);
                    border-color: var(--cms-block-fc);
                }
            }
        }
    }

    &-Title {
        color: var(--cms-block-fc);
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.003em;
        position: relative;
        margin: 0 -14px 28px;
        padding: 0 14px;
        text-align: center;

        @include desktop {
            margin: 0 0 28px;
        }

        a,
        span {
            background: $white;
            padding: 0 16px;
            display: inline-block;
        }

        a {
            color: var(--cms-block-fc);
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;

            &:hover {
                text-decoration: none;
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            border-bottom: 1px solid var(--cms-block-fc);
        }
    }

    &-SeeAll {
        height: 0;
        text-align: right;

        a {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-decoration: underline;
            z-index: 1;
            position: absolute;
            bottom: 6px;
            right: 0;

            @include after-tablet {
                position: relative;
                bottom: 0;
            }


        }
    }

    &-Placeholder {
        display: flex;
        flex-flow: nowrap;
        height: 350px;
        margin-bottom: 50px;
        figure {
            flex: auto;
            padding: 5px;
        }
    }

    &-Tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 3px;

        @include desktop {
            margin-bottom: 28px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar {
            height: 0;
            background-color: $color-grey-E5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $black;
        }
    }

    &-Tab {
        background: $greyF0F0F0;
        color: $black;
        cursor: pointer;
        padding: 8px 24px;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-right: 8px;

        @include desktop {
            background: none;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.2px;
            padding: 0;
            margin-right: 40px;
        }

        &_isActive{
            background-color: $black;
            color: $white;

            @include mobile {
                order: -1;
            }

            @include desktop {
                background: none;
                color: $black;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                text-decoration: underline;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:empty {
            padding: 0;
        }
    }

    &-TabContent {
        display: none;

        &_isActive {
            display: block;
            }
        }
    }

    &-Edit{
        &_isVisible {
            .ProductActions {
                &-ActionButtons {
                    .ProductWishlistButton {
                        &-Button {
                            &.Button{
                                border: unset;
                                background: unset;
                                width: initial;
                                height: initial;

                                &:hover {
                                    height: initial;
                                }

                                img {
                                    display: block;
                                }
                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .ProductWishlistButton {
                width: 100%;
                height: auto;
                &-Button {
                    &.Button{
                        border: 1px solid #000000;
                        background: #000000;
                        width: 100%;
                        height: 48px;

                        &:hover {
                            height: 48px;
                        }
                        &:focus {
                            color: #fff;
                        }
                        img {
                            display: none;
                        }
                        span {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
