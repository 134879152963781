/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --price-color: #{$black};
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #808080;
}

.ProductPrice {
    color: var(--price-color);
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    vertical-align: middle;
    margin-block-end: 0;
    letter-spacing: -0.0008em;
    display: inline-flex;
    width: 100%;

    @include mobile {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    @include narrow-desktop {
        font-size: 13px;
    }

    @include wide-mid-ultra-wide-desktop {
        font-size: 13px;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-inline-end: 4.6%;

        @include narrow-desktop {
            margin-inline-end: 3%;
        }

        @include wide-mid-ultra-wide-desktop {
            margin-inline-end: 3%;
        }
    }

    &-UnitValue {
        display: inline-flex;
        margin-left: auto;

        .value {
            margin-top: 6px;
            font-weight: 400;
            font-style: italic;
            font-size: 11px;
        }
    }

    del {
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        margin-inline-end: 4.6%;

        @include narrow-desktop {
            font-size: 11px;
            margin-inline-end: 2%;
        }

        @include wide-mid-ultra-wide-desktop {
            font-size: 11px;
            margin-inline-end: 2%;
        }

        @include mobile {
            font-weight: 600;
            font-size: 10px;
        }
    }

    &-HighPrice {
        color: var(--price-discount-color);
    }

    &-PriceValue {
        margin-block-start: 6px;
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        margin-block-start: 4px;
    }

    &-PriceBadge {
        color: var(--price-color);
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4.6%;
        white-space: nowrap;

        @include narrow-desktop {
            margin-inline-end: 2%;
        }

        @include wide-mid-ultra-wide-desktop {
            margin-inline-end: 2%;
        }

        &.StartingPrice,
        &.Startingfrom,
        &.Έως {
        // &.AsLowas {
            display: none;
        }
    }

    &-DiscountPercentage {
        border: 1px solid $foux;
        color: $foux;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.02em;
        padding: 3px 8px;
        margin-block-start: 6px;
        display: inline-block;

        @include narrow-desktop {
            padding: 3px 4px;
        }

        @include wide-mid-ultra-wide-desktop {
            padding: 3px 4px;
            white-space: nowrap;
        }

        @include mobile {
            padding: 3px 4px;
        }

        .CHANEL &,
        .ProductActions-BundleProduct & {
            display: none !important;
        }
    }

    &_foucsiaPrice,
    &_offerDiscount {
        color: $foux;
    }
}
