/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    &.ProductActions-Attributes {
        margin-top: 20px;
        margin-bottom: 32px;

        @include desktop {
            margin-top: 12px;
        }
    }

    &-Hint {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin: 20px 0 -12px;

        pre {
            white-space: normal;
        }
    }

    &-SwatchList,
    &-DropDownList {
        text-align: center;
    }

    &-Title {
        font-weight: 700;
        margin-block: 24px 12px;

    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding-inline-start: 4px;
        padding-block-start: 4px;

        .ProductConfigurableAttributeDropdown + & {
            margin-top: 12px;
        }

        + .ProductConfigurableAttributeDropdown {
            margin-top: 12px;
        }

        .ProductConfigurableAttributes-SwatchListWrapper & {
            padding: 0;

            @include mobile {
                flex-wrap: nowrap;
            }

            .ProductAttributeValue {
                .Field-CheckboxLabel {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -.0008em;
                    padding: 4px 12px;
                }

                &-Text {
                    label:first-of-type {
                        .input-control {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: 0;
                            width: auto;
                            height: auto;
                            background: none;
                            border-width: 1px;
                        }
                    }
                }
            }
        }
    }

    &-SwatchListWrapper {
        @include mobile {
            overflow-y: hidden;
            overflow-x: auto;

            &::-webkit-scrollbar-track {
                border-radius: 5px;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar {
                height: 0;
                background-color: $color-grey-E5;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $black;
            }
        }
    }

    &-DropDownList {
        width: 100%;
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block: var(--option-margin-block);
        margin-inside: var(--option-margin-inline);
    }

    &-Expandable:first-of-type {
        border-block-start: none;
    }
}
