/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.SampleProductCategoryPage {
    &-Heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        max-width: 482px;
        margin: 0 auto 12px;
        letter-spacing: -0.2px;
        text-align: center;
        padding: 0 16px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
        }
    }

    &-SubHeading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 22px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        gap: 24px;
        border-bottom: 1px solid $grey;
        margin: 0 16px 20px;

        &:after {
           content: attr(data-count);
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
        }
    }

    &-TopButton {
        padding: 0 16px;
        margin-bottom: 32px;
    }

    &-Static {
        @include desktop {
            width: calc(60% - 48px);
        }

        @include ultra-wide-desktop {
            width: 70%;
        }

        @include ultra-narrow-desktop {
            width: 100%;
        }
    }

    &-Floating {
        background: $icegrey;
        padding: 24px;

        @include mobile {
            margin-left: 16px;
            margin-right: 16px;
            margin-top: 20px;
            padding: 16px;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            width: 40%;
            align-self: flex-start;
        }

        @include ultra-wide-desktop {
            width: 30%;
        }

        @include ultra-narrow-desktop {
            width: 100%;
        }

        &Title {
            border-bottom: 1px solid $grey;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            padding: 24px 0 16px;
            margin-bottom: 24px;
        }

        .ExpandableContent {
            border: 0 none !important;
            margin-bottom: 24px;

            &-Button {
                display: block;
                padding-top: 0;
                border-bottom: 1px solid $grey !important;

                @include desktop {
                    padding-bottom: 17px;
                }

                .ChevronIcon {
                    position: absolute;
                    top: 0;
                    right: 0;

                    @include desktop {
                        display: none !important;
                    }
                }
            }

            &-Heading {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.2px;

                @include desktop {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &-SubHeading {
                color: $black;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                padding-top: 9px;
                width: 100%;

                @include desktop {
                    padding-top: 17px;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                        content: attr(data-count);
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                    }
                }
            }
        }

        .CartItem {
            border-bottom: 0 none;
        }

        .CartItem-Wrapper {
            background: none;
            padding-left: 0;
            padding-right: 0;
        }

        .CartPage-CheckoutButton {
            width: 100%;
        }
    }

    &-ColumnWrapper {
        // display: flex;
        margin-bottom: 2.4rem;

        @include mobile {
            display: block;
            padding: 1.2rem 0;
        }
    }
    .ContentWrapper {
        @include desktop {
            display: flex;
            gap: 48px;
            justify-content: space-between;
        }

        @include ultra-narrow-desktop {
            display: block;
        }

        @include mobile {
            padding: 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-right: 1.8rem;

            @include mobile {
                margin-right: 0;
            }
        }
    }

    &-Page {
        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include ultra-narrow-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include ultra-wide-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        .ProductCard-Figure {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
