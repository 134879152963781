/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    line-height: 16px;

    &-Brand {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.2px;
        margin: 0 0 8px;
        display: block;

        @include desktop {
            margin-bottom: 10px;
        }

        .CHANEL & {
            font-weight: 700;
        }

        a {
            font-size: 20px;
            display: block;
        }
    }

    &-Title {
        .CHANEL & {
            font-weight: 700;
        }
    }

    &-Barcode {
        margin-inline-start: 5px;
    }

    &-Barcode {
        margin-inline-start: 5px;
    }

    &-AlternativeName,
    &-Title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 15px;
        display: block;

        &_isChanel_CHANEL {
            font-size: 20px;
        }

        .amgiftcard & {
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.2px;
            margin-bottom: 30px;
            text-align: center;

            @include desktop {
                font-size: 20px;
                line-height: 28px;
                text-align: left;
                margin-bottom: 3px;
            }
        }
    }

    &-AlternativeName {
        font-weight: 400;
        margin-top: -7px;
    }

    &-ShortDescription {
        font-size: 14px;
        line-height: 20px;
        margin-block: 16px;

        &.isGift {
            margin-top: 0;
            margin-bottom: 40px;
        }

        &_Gift {
            &-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
                color: $foux;
            }
        }
    }

    &-Description {
        margin-bottom: 20px;

        &.ExpandableContent {
            &:last-of-type {
                border-bottom: 0;
                margin-bottom: 20px;
            }
        }

        .ExpandableContent {
            &-Content {
                max-height: 0;
                overflow: hidden;
                opacity: 0;

                &_isContentExpanded {
                    opacity: 1;
                    max-height: 100%;
                }
            }

            &-Button {
                cursor: pointer;
                padding-top: 18px;
                padding-bottom: 12px;
                justify-content: flex-start;
            }

            &-Heading {
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &-DescriptionTwoColumns {
        @include desktop {
            display: flex;
            margin: 0 -10px;
        }
    }

    &-DescriptionTwoColumn {
        @include desktop {
            padding: 0 10px;
            width: 50%;
            flex-shrink: 0;
        }
    }

    &-SelectedBundleProduct {
        border-bottom: 1px solid $color-grey-E5;
        display: grid;
        grid-template-columns: 10% auto;
        letter-spacing: -0.0008em;
        padding: 10px 0;

        @include desktop {
            grid-template-columns: 25% auto;
        }

        &Right {
            display: grid;
            text-align: right;
        }

        &Title {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
        }

        &Qty {
            line-height: 22px;
        }

        &Price {
            margin-top: 10px;
            letter-spacing: 1.25px;
        }
    }

    &-SelectedBundleProducts {
        display: grid;
        padding: 0 5px 10px;
        max-height: 235px;
        overflow-y: auto;
        overflow-x: hidden;

        @include desktop {
            padding: 13px 22px 18px;
        }
    }

    &-BundleAction {
        width: 100%;
    }

    &-BundleProduct {
        grid-column: 1 / -1;
        margin-top: 30px;

        @include desktop {
            margin-top: 50px;
        }

        &Cols {
            display: flex;
            flex-direction: column;
            gap: 53px;

            @include desktop {
                //display: grid;
                //grid-column-gap: 5px;
                //grid-template-columns: 1fr 1fr 1fr;
                flex-direction: row;
                gap: 6px;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            @include tablet {
                display: flex;
            }

            >div {
                @include desktop {
                    width: calc(50% - 4px);
                    flex-shrink: 0;
                    margin-bottom: 6px;
                }

                @include wide-desktop {
                    width: calc(33.3333% - 4px)
                }

                &.ProductActions-BundleProductAction {
                    @include desktop {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &-BundleProductDescription {
        grid-column: 1 / -1;
        margin-top: 30px;

        .ExpandableContent {
            &-Button {
                display: none;
            }

            &-Content {
                max-height: 100%;
                opacity: 1;
            }
        }
    }

    &-BundleProductDescriptionButton {
        cursor: pointer;
        color: $black;
        display: flex;
        align-items: center;
        gap: 14px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        padding-top: 18px;
        padding-bottom: 12px;
        margin-bottom: 20px;

        &:after {
            content: "";
            width: 5px;
            height: 5px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent $black $black transparent;
            transform: rotate(45deg);
            margin-top: -4px;
        }
    }

    &-BundleProductActionWrapper {
        @include desktop {
            border: 1px solid $color-grey-E5;
        }
    }

    &-BundleProductAction {
        .ProductBundleItem-Title {
            opacity: 0;

            @include mobile {
                display: none;
            }
        }

        .ProductActions-Qty.Field {
            width: 0;
            height: 0;
            overflow: hidden;
            border: 0 none;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &-BundleProductActionBottom {
        border-top: 1px solid black;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @include desktop {
            flex-direction: row;
            padding-top: 0;
            gap: 0;
        }

        .ProductActions-BundleProductActionPrice {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0 10px;
        }

        .ProductActions-Schema {
            min-width: 0;
        }

        .ProductPrice-PriceValue,
        .ProductActions-PriceWrapper {
            margin: 0;
        }

        .ProductActions-PriceWrapper {
            .ProductPrice {
                min-height: 0;
                margin-left: 12px;
                text-align: center;

                @include desktop {
                    font-size: 16px;
                }

                del.ProductPrice-HighPrice {
                    margin-right: 12px;
                    font-size: 14px;
                }

                ins.ProductPrice-Price {
                    margin-right: 0;
                }
            }
        }

        .ProductActions-AddToCartWrapper {
            margin: 0;
            flex-shrink: 0;
            width: 100%;

            @include desktop {
                width: 47%;
            }

            .AddToCart.Button {
                background: $black;
                color: $white;
                margin: 0;

                &:not([disabled]):hover {
                    background: $white;
                    color: $black;
                }
            }
        }
    }

    &-Section_type_bundle_items {
        @include desktop {
            display: flex;
            justify-content: space-between;
            gap: 53px;
        }
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            margin-block-end: 10px;
        }
    }

    &-Sku {
        order: 1;
        margin-inline-end: 5px;

        &+.ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        color: var(--primary-base-color);
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        &:hover {
            color: var(--primary-dark-color);
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }
            }

            &_alerts {
                width: 100%;
                margin-block-start: 10px;
                margin-block-end: 10px;
                text-align: center;
            }
        }
    }

    &-Schema {
        min-width: 150px;

        .amgiftcard & {
            opacity: 0;
            width: 0;
            height: 0;
            min-width: 0;
            min-height: 0;
            overflow: hidden;
        }
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        @include desktop {
            margin-block-start: 8px;
        }

        .ProductPrice {
            @include mobile {
                font-size: 14px;
            }

            del {
                margin-top: 6px;
            }

            &-Price,
            del {
                margin-inline-end: 17px;
            }

            del {
                @include mobile {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

    &-ActionButtons {
        display: flex;
        gap: 20px;
    }

    &Share {
        display: inline-block;
        width: 23px;
        height: 23px;

        &-Button {
            height: 23px;
            padding: 0;
            display: flex;
            align-items: center;

            &:hover,
            &:focus {
                background-color: unset;
                height: 23px;
                padding: 0;
            }
        }

        &-container {
            background: $white;
            box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
            position: absolute;
            padding: 10px 0;
            width: 248px;
            right: -43px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin-top: 14px;

            @include desktop {
                width: 295px;
            }

            &:before {
                content: "";
                box-shadow: -3px -4px 5px rgba(0, 0, 0, 0.05);
                width: 20px;
                height: 20px;
                background: $white;
                transform: rotate(45deg);
                position: absolute;
                top: -5px;
                right: 45px;
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                }
            }
        }
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-AddToCartFixed {
        position: fixed;
        inset-block-end: var(--footer-total-height);
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        display: flex;
        padding: 12px 16px;
        border-block-start: 1px solid var(--primary-divider-color);
        inset-inline-start: 0;

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;
        }
    }

    .AddToCart {
        &.Button {
            min-width: 0;
            background-color: $black;
            border-color: $black;
            color: $white;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.04em;

            &:not([disabled]) {
                &:hover {
                    @include desktop {
                        background-color: $white;
                        color: $black;
                    }
                }
            }
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        margin-block-start: 20px;

        .amgiftcard & {
            @include desktop {
                justify-content: flex-end;
            }
        }

        .ProductActions-AddToCart {

            @include ultra-narrow-desktop {
                order: 10;
                margin-block-start: 24px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }
    }

    &-FindStore {
        margin: 12px 0 0;
        display: none;

        a.Button,
        .Button {
            display: flex;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.04em;
            gap: 17px;
            background: $white;
            color: $black;
            border-color: $black;

            &:not([disabled]) {
                &:hover {
                    background: $black;
                    color: $white;

                    path {
                        stroke: $white;
                    }
                }
            }
        }
    }

    &-SizeGuide {
        &Popup {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 20px 0 12px;

            +.ProductActions-Help {
                margin-top: 0;
            }
        }

        &Link {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            gap: 14px;
            margin-top: 20px;
        }
    }

    &-SkuNBarcode {
        padding: 5px 0;

        @include ultra-narrow-desktop {
            font-size: 12px;
        }
    }

    &-Help {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0 22px;

        .ProductActions-SizeGuideLink~& {
            margin-top: 12px;
        }

        &Link {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            gap: 14px;
        }
    }

    &_limitation {
        margin-top: 12px;

        .label {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin-bottom: 12px;
            font-style: italic;
        }
    }

    &_aroma_notes {
        margin-top: 20px;

        .label {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin-bottom: 20px;

            @include desktop {
                display: none;
            }
        }

        &_columns {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include desktop {
                flex-direction: row;
                gap: 50px;
            }

            @include ultra-narrow-desktop {
                gap: 14px;
            }
        }

        &_column {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            @include desktop {
                flex-direction: column;
                gap: 16px;
                width: 33.3333%;
                margin-bottom: 32px;
            }

            &:last-child {
                @include mobile {
                    margin-bottom: 32px;
                }
            }

            &:not(:first-child) {
                &:before {
                    @include desktop {
                        content: "";
                        height: calc(100% - 40px);
                        width: 0;
                        border-left: 1px solid $grey;
                        position: absolute;
                        bottom: 0;
                        left: -25px;
                    }

                    @include ultra-narrow-desktop {
                        left: -7px;
                    }
                }
            }
        }

        &_content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            @include desktop {
                flex-direction: column;
                gap: 4px;
                text-align: center;
                min-height: 86px;
                padding-top: 12px;
            }
        }

        &_title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            flex-shrink: 0;
            min-width: 91px;

            @include desktop {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                white-space: nowrap;
            }

            @include ultra-narrow-desktop {
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
            }
        }

        &_subtitle {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;

            @include desktop {
                color: $grey727272;
                display: flex;
                flex-direction: column;
            }
        }

        &__item {
            &:not(:last-child) {
                &:after {
                    content: ",";

                    @include mobile {
                        margin-right: 5px;
                    }
                }
            }
        }

        &_icon {
            height: 24px;
            flex-shrink: 0;

            img {
                vertical-align: top;
            }
        }
    }

    &-Qty {
        &.Field {
            border: 1px solid $black;
            margin-block: 0;
            margin-inline-end: 12px;

            .amgiftcard & {
                display: none !important;
            }

            input {
                font-weight: 500;
                font-size: 12px;
                min-width: 42px;
                letter-spacing: -0.0008em;
            }

            ;
        }

        [type='number']~button {
            cursor: pointer;
            border: 0 none;
            height: 46px;
            width: 41px;

            &:hover {
                border-color: var(--primary-dark-color);
            }
        }
    }

    &-AttributesWrapper {
        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }

        &_isSimple {
            .ProductAttributeValue {
                &-ImageWrapper {
                    padding: 12px 12px;
                }

                &-Color {
                    &:before {
                        box-shadow: none;
                    }
                }
            }

            .ProductConfigurableAttributes-SwatchList {
                padding: 0;
            }
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        margin-block-end: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.isGift {
            justify-content: flex-end;
            margin-bottom: 15px;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
            cursor: pointer;

            .CHANEL & {
                display: none;
            }
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    &-Tags {
        margin: 0 0 4px;
    }

    &-Tag {
        background: $greyF0F0F0;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        margin: 0 8px 8px 0;
        padding: 4px 8px;
        letter-spacing: 0.02em;
        display: inline-block;
        vertical-align: top;
    }

    &-TagBlack {
        background: $black;
        color: $white;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        margin: 0 8px 8px 0;
        padding: 4px 8px;
        letter-spacing: 0.02em;
        display: inline-block;
        vertical-align: top;
    }

    &-GiftOptions {
        border-top: 1px solid $xryso;

        @include desktop {
            border-bottom: 1px solid $xryso;
        }

        &_Fields {
            display: flex;
            flex-direction: column;
        }

        .Field {
            margin: 16px 0 0;

            .LabelWrapper {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                font-weight: 400;
                font-size: 16px;

                @include desktop {
                    font-size: 14px;

                }
            }

            .radio {
                --box-color: #fff;
                background-color: var(--box-color);
                border: 1px solid rgba(0, 0, 0, 0.25);
                border-radius: var(--checkbox-border-radius);
                cursor: pointer;
                display: inline-block;
                height: 21px;
                position: relative;
                padding: 0;
                transition-duration: .25s;
                transition-property: background-color, border-color;
                transition-timing-function: ease-in;
                width: 21px;
                will-change: background-color, border-color;
                flex-shrink: 0;

                @include desktop {
                    width: var(--checkbox-width);
                    height: var(--checkbox-height);
                }

                border-radius: 50%;
                margin-bottom: 10px;
                margin-right: 16px;
            }

            .selected {
                border-width: 4px;
                border-color: #000000;
            }

            .CostDetails {
                margin-top: 8px;
                display: block;
            }


            textarea,
            input {
                width: 100%;
            }

            &.am_giftcard_sender_name {
                order: -2;
            }

            &.am_giftcard_recipient_name {
                order: -1;
            }
        }

        &_template {
            width: 100%;

            @include desktop {
                max-width: 576px;
                margin: 0 auto;
            }

            &_image {
                padding: 0 7px 7px;

                img {
                    border: 1px solid transparent;
                    border-radius: 3px;
                    overflow: hidden;
                    cursor: pointer;

                    @include mobile {
                        width: auto;
                        max-width: 100%;
                        max-height: 83px;
                    }
                }

                &.selected {
                    img {
                        border-color: $grey2D2D2D;
                        cursor: default;
                    }

                    &:after {
                        content: "";
                        width: 60px;
                        border-bottom: 1px solid $black;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }

            &_Slider {
                margin: 0 21px;
                height: 100%;

                @include mobile {
                    // margin: 0 -16px;
                    // padding: 0 110px 0 8px;
                    overflow: hidden;
                }

                .slick-list {
                    @include mobile {
                        overflow: visible;
                    }
                }
            }
        }

        &_SliderImage {
            &.ProductGallery-SliderImage {
                height: 100%;
            }
        }

        &_short_description {
            background: $greyF8F8F8;
            padding: 58px 16px 42px;
            margin: -20px -16px 2px;
            z-index: -1;

            @include desktop {
                margin: -84px 0 25px;
                padding: 122px 20px 58px;
            }

            &_content {
                max-width: 623px;
                margin: 0 auto;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.0008em;
            }
        }

        &_amount {
            .Field-Label {
                background: $greyF8F8F8;
                padding: 12px 16px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.2px;
                margin-bottom: 25px;
                margin-top: 16px;
            }

            .Field-Value {
                font-weight: 400;
                font-size: 14px;
                letter-spacing: -0.0008em;
                padding-left: 52px;
                margin-bottom: 22px;

                .Field {
                    margin: 0;
                    position: static;

                    label {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        display: flex;
                        max-width: 100%;
                        cursor: pointer;
                        padding-left: 20px;
                    }
                }

                &.selected {
                    .Field [type='radio']+.input-control {
                        border-width: 4px;
                        border-color: $black;
                    }
                }
            }

            .Field-Values {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;

                @include wide-desktop {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }

    &-CustomList {
        border: 2px solid $black;
        padding: 30px 20px;
        margin: 50px 0 0;

        &.gift_type {
            border-color: $gold1;

            .DIOR & {
                border-color: $black;
            }
        }

        &Label {
            background: $white;
            font-family: $font-BlackerProDisplay;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
            position: absolute;
            top: -21px;
            left: 10px;
            padding: 0 20px;

            .gift_type & {
                color: $gold1;
            }

            .DIOR .gift_type & {
                display: none;
            }
        }

        &Item {
            display: flex;
            flex-direction: column;

            @media (min-width: 1024px) {
                flex-direction: row;
                align-items: center;
                gap: 16px;
            }

            &:not(:first-child) {
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid #D9D9D9;
            }

            .ProductActions-CustomListImage {
                padding-bottom: 116px;

                @media (min-width: 1024px) {
                    max-width: 94px;
                    flex-shrink: 0;
                    overflow: hidden;
                }
            }
        }

        &Text {
            color: $grey2D2D2D;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            word-break: break-word;
            letter-spacing: -0.0008em;

            p {
                margin-bottom: 0;
            }
        }

        &Title {
            color: $grey2D2D2D;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            .gift_type & {
                margin-top: 16px;

                @include wide-desktop {
                    margin-top: 0;
                }
            }

            .DIOR & {
                font-size: 12px;
                line-height: 16px;
            }

            +.ProductActions-CustomListText {
                margin-top: 3px;
            }
        }

        &TitleDior {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.0008em;
            margin-bottom: 4px;
        }

        &ImageDior {
            width: 67px;
            margin-left: -5px;
            margin-bottom: 4px;
        }

        &Link {
            color: $grey2D2D2D;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: inline-flex;
            align-items: center;
            gap: 12px;
            margin-top: 9px;

            .DIOR & {
                margin-top: 4px;
            }
        }
    }

    &-Related {
        margin: 20px 0;

        @include desktop {
            margin: 10px 0 20px;
        }

        &_layout_grid {
            display: flex;
            gap: 12px;

            .ProductCard {
                border: 1px solid transparent;
                width: 70px;
                margin: 0 !important;

                &:first-child {
                    border-color: $black;
                }

                &-FigureReview {
                    padding-bottom: 0;
                }

                &-Picture {
                    padding-bottom: 68px;
                    vertical-align: top;
                }
            }
        }
    }

    &-Categories {
        border-top: 1px solid $grey;
        padding: 16px 16px 0;

        @include mobile {
            margin: 0 -16px 12px;
            padding-top: 20px;
        }

        &Title {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.0008em;
            margin-bottom: 13px;
        }

        &List {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        &Item {
            background: $greyF0F0F0;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.02em;
            padding: 4px 8px;

            &:hover {
                background: $black;
                color: $white;
                text-decoration: none !important;
            }
        }
    }

    &-DermoRecommended {
        margin-top: 25px;
        text-align: center;

        @include mobile {
            margin-bottom: 12px;
        }
    }

    &-BrandWrapper {
        &.ContentWrapper {
            @include mobile {
                padding: 0;
                margin-right: -16px;
                margin-left: -16px;
            }
        }

        .ProductActions-Brand {
            &.DIOR {
                background: $black;
                color: $white;
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;
                margin-bottom: 7px;
                display: block;

                a {
                    display: block;
                    padding: 7px;
                }

                img {
                    filter: brightness(0) invert(1);
                    max-width: 85px;

                    @include desktop {
                        max-width: 104px;
                    }
                }

                &.reseller {
                    background: unset;

                    img {
                        filter: unset;
                        max-width: 320px;
                    }
                }
            }

            &.CHANEL {
                font-family: $font-BlackerProDisplay;
                text-align: center;
                font-size: 31px;
                line-height: 1;
                padding: 11px 0 21px;
                display: block;
                margin-bottom: 0;

                @include desktop {
                    padding: 41px 0 45px;
                }

                &:before {
                    content: "";
                    background: $black;
                    position: absolute;
                    width: 10000px;
                    top: 0;
                    bottom: 0;
                    height: 6px;
                    left: 50%;
                    transform: translateX(-50%);

                    @include desktop {
                        height: 8px;
                    }
                }

                a {
                    display: inline-block;
                    vertical-align: top;
                    padding: 7px;
                }

                img {
                    width: auto;
                    vertical-align: top;

                    @include mobile {
                        height: 12px;
                    }
                }

                &.ProductActions-Footer {
                    padding-bottom: 30px;
                    padding-top: 36px;

                    @include mobile {
                        padding-bottom: 0;
                        padding-top: 26px;
                    }

                    img {
                        @include mobile {
                            height: auto;
                            max-height: 110px;
                        }
                    }
                }
            }
        }
    }

    .Button {
        &.FreeGift {
            width: 100%;
        }
    }

    &-chance_finder {
        width: auto;
        max-width: 100%;
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include mobile {
            margin-bottom: 12px;
        }
    }

    &-cms_block {
        width: auto;
        max-width: 100%;
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include mobile {
            margin-bottom: 12px;
        }
    }

    &-OfferPriceDetails {
        margin-top: 12px;
        font-size: 13px;

        :not(:first-child) {
            margin-top: 6px;
            font-size: 13px;
        }
    }
}