/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductSizeGuidePopup {
  &-cols {
    @include mobile {
      position: static;
    }

    @include desktop {
      display: flex;
      flex-direction: row;
    }
  }

  &-col {
    @include mobile {
      position: static;
    }

    @include desktop {
      flex: 1;
    }
  }

  &_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
    margin-bottom: 8px;

    @include desktop {
      margin-bottom: 32px;
    }
  }

  &_subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
    margin-bottom: 24px;
  }

  &_description {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.0008em;
  }

  &-content {
    font-size: 14px;
    padding: 24px 16px 28px;

    @include desktop {
      padding: 32px;
    }

    .Image,
    iframe {
      vertical-align: top;
      width: 100% !important;
    }
  }

  &_Images {
    height: 100%;

    @include mobile {
      position: static;
    }

    img {
      vertical-align: top;
      object-fit: cover;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      font-family: 'object-fit: cover;';
      width: 0;
      min-width: 100%;
      min-height: 100%;

    }
  }

  .CmsBlock-Wrapper,
  .RenderWhenVisible {
    height: 100%;

    @include mobile {
      position: static;
    }
  }

  .Field {
    margin-top: 16px;
  }

  .FieldForm-Fields {
    margin-bottom: 16px;

    @include desktop {
      text-align: left;
    }
  }

  .Button {
    background-color: $black;
    color: $white;

    &:not([disabled]):hover {
      background-color: $white;
      color: $black;
    }
  }

  .ContactForm {
    @include desktop {
      text-align: right;
    }
  }

  .Popup {
    &-Content {
      width: 90%;
      max-width: 982px;
      padding: 0;
      border-radius: 0;

      @include mobile {
        width: 100%;
        min-width: 0;
        max-width: 360px;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    &-Heading {
      display: none;
    }

    &-Header {
      position: static;
      display: block !important;
      padding: 0;
    }

    &-CloseBtn {
      position: absolute;
      z-index: 5;

      @include mobile {
        right: 0 !important;
        top: 0 !important;
      }

      @include desktop {
        top: 8px !important;
        right: 8px !important;
      }

      .CloseIcon {
        width: 24px !important;
        height: 24px !important;
        vertical-align: top;
      }
    }
  }

  &.Popup_isVisible {
    @include mobile {
      top: 0;
      height: 100vh;
      align-items: center;
    }
  }
}
